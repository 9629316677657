import {GalleryContent as IGalleryContent} from "../types";

export const GalleryContent: IGalleryContent = {
	titleKey: "gallery.title",
	titleDescriptionKey: "",
	images: [
		window.location.origin + "/gallery/magazine_1.webp",
		window.location.origin + "/gallery/magazine_2.webp",
		window.location.origin + "/gallery/magazine_3.webp",
		window.location.origin + "/gallery/full_canvas.webp",
		window.location.origin + "/gallery/suites1.webp",
		window.location.origin + "/gallery/suites2.webp",
		window.location.origin + "/gallery/handSewnLinings.webp",
		window.location.origin + "/gallery/handSewnLinings2.webp",
		window.location.origin + "/gallery/handSewnCanvas.webp",
	],
};
