import {CraftsmanshipDetailContent as ICraftsmanshipDetailContent} from "../types";

export const CraftsmanshipDetailContent: ICraftsmanshipDetailContent = {
	titleKey: "craftsmanshipDetail.title",
	titleDescriptionKey: "",
	sections: [
		{
			titleKey: "craftsmanshipDetail.sections.0.title",
			paragraphKeys: ["craftsmanshipDetail.sections.0.paragraphs.0"],
			image:
				window.location.origin + "/craftsmanshipDetail/proportionStripes.webp",
		},
		{
			titleKey: "craftsmanshipDetail.sections.1.title",
			paragraphKeys: ["craftsmanshipDetail.sections.1.paragraphs.0"],
			image:
				window.location.origin +
				"/craftsmanshipDetail/slantedBreastPocket.webp",
		},
		{
			titleKey: "craftsmanshipDetail.sections.2.title",
			paragraphKeys: ["craftsmanshipDetail.sections.2.paragraphs.0"],
			image:
				window.location.origin +
				"/craftsmanshipDetail/traditionalHandmadeUnderCollar.webp",
		},
		{
			titleKey: "craftsmanshipDetail.sections.3.title",
			paragraphKeys: ["craftsmanshipDetail.sections.3.paragraphs.0"],
			image:
				window.location.origin +
				"/craftsmanshipDetail/handmadeWorkingButtonHolesAtSleeve.webp",
		},
		{
			titleKey: "craftsmanshipDetail.sections.4.title",
			paragraphKeys: ["craftsmanshipDetail.sections.4.paragraphs.0"],
			image:
				window.location.origin +
				"/craftsmanshipDetail/ironingWithPerfection.webp",
		},
		{
			titleKey: "craftsmanshipDetail.sections.5.title",
			paragraphKeys: ["craftsmanshipDetail.sections.5.paragraphs.0"],
			image:
				window.location.origin +
				"/craftsmanshipDetail/handmadeButtonHoles.webp",
		},
		{
			titleKey: "craftsmanshipDetail.sections.6.title",
			paragraphKeys: ["craftsmanshipDetail.sections.6.paragraphs.0"],
			image:
				window.location.origin +
				"/craftsmanshipDetail/neapolitanShoulderWithCreases.webp",
		},
		{
			titleKey: "craftsmanshipDetail.sections.7.title",
			paragraphKeys: ["craftsmanshipDetail.sections.7.paragraphs.0"],
			image:
				window.location.origin +
				"/craftsmanshipDetail/accommodatingTrousers.webp",
		},
	],
};
