import {
	Box,
	Center,
	Divider,
	Group,
	Stack,
	Title,
	UnstyledButton,
} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {NavigationLink} from "../../types";
import CustomTitle from "../CustomTitle";
import MobileBurger from "./mobileBurger";
import MobileMenu from "./mobileMenu";
import TranslationButton from "./translationButton";

type Props = {
	title: string;
	links: NavigationLink[];
	redirectIconPath: string;
};

export default function NavigationBar({title, links, redirectIconPath}: Props) {
	const {t} = useTranslation();
	const isDesktop = useMediaQuery("(min-width: 768px)");

	const [isTriggedMenu, setIsTriggedMenu] = useState(false);

	return (
		<Box
			sx={{
				...(isDesktop && {paddingTop: 25, paddingBottom: 25}),
				...(!isDesktop && {paddingTop: 10, paddingBottom: 10}),
				// background: "0F4AE6",
			}}
		>
			<MobileMenu
				title={title}
				links={links}
				isTriggedMenu={isTriggedMenu}
				setIsTriggedMenu={setIsTriggedMenu}
			/>
			{isDesktop && <TranslationButton />}

			<Stack align={isDesktop ? "center" : "initial"}>
				<Group>
					<MobileBurger
						isShown={!isDesktop}
						isTriggedMenu={isTriggedMenu}
						setIsTriggedMenu={setIsTriggedMenu}
					/>

					<Link to={redirectIconPath}>
						<UnstyledButton>
							<Title
								order={1}
								align="center"
								sx={{
									color: "#0F4AE6",
									fontFamily: "Savoye LET Plain",
									fontStyle: "italic",
									fontSize: "4rem",
									"@media only screen and (max-width: 768px)": {
										paddingTop: "10px",
										fontSize: "2rem !important",
									},
								}}
							>
								{title}
							</Title>
						</UnstyledButton>
					</Link>
				</Group>

				{isDesktop && (
					<Group spacing={70}>
						{links.map((link, index) => (
							<Link key={index} to={link.path}>
								<UnstyledButton sx={{fontWeight: 700, fontSize: "1.2rem"}}>
									{t(link.titleKey)}
								</UnstyledButton>
							</Link>
						))}
					</Group>
				)}
			</Stack>
		</Box>
	);
}
