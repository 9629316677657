import "./App.css";
import {MantineProvider, Text} from "@mantine/core";
import {BrowserRouter} from "react-router-dom";
import Routes from "./routes";

export default function App() {
	return (
		<BrowserRouter>
			<MantineProvider
				theme={{
					fontFamily: "George Regular",
					headings: {
						fontFamily: "George Italic",
					},
					breakpoints: {
						xs: 576,
						sm: 768,
						md: 992,
						lg: 1600,
						xl: 1980,
					},
					fontSizes: {
						xs: 10,
						sm: 12,
						md: 14,
						lg: 16,
						xl: 24,
					},
				}}
				withGlobalStyles
				withNormalizeCSS
			>
				<Routes />
			</MantineProvider>
		</BrowserRouter>
	);
}
