import {Title, TitleOrder, TitleProps} from "@mantine/core";
import useHeaderStyle from "./useHeaderStyle";
import classNames from "classnames";

export interface CustomTitleProps extends Omit<TitleProps, "size"> {
	heading: `h${TitleOrder}`;
}

export default function CustomTitle(props: CustomTitleProps) {
	const {heading, className = ""} = props;
	const {classes} = useHeaderStyle(heading);
	return (
		<Title {...props} className={classNames(classes.heading, className)} />
	);
}
