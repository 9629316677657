import {AboutMeContent as IAboutMeContent} from "../types";

export const AboutMeContent: IAboutMeContent = {
	titleKey: "aboutMe.title",
	titleDescriptionKey: "",
	// titleImage: window.location.origin + "/header/contemporaryTradition.png",
	introductionSection: {
		titleKey: "aboutMe.introductionSection.title",
		paragraphKeys: [
			"aboutMe.introductionSection.paragraphs.0",
			"aboutMe.introductionSection.paragraphs.1",
			"aboutMe.introductionSection.paragraphs.2",
		],
		image: window.location.origin + "/aboutMe/profile.webp",
	},
	certificateSection: {
		titleKey: "aboutMe.certificateSection.title",
		groups: [
			{
				categoryKey: "aboutMe.certificateSection.groups.3.category",
				certificates: [
					{
						titleKey: "aboutMe.certificateSection.groups.3.certificates.0",
						image:
							window.location.origin +
							"/certificates/wfmtc_cerificate_of_attendance_2019.webp",
					},
				],
			},
			{
				categoryKey: "aboutMe.certificateSection.groups.2.category",
				certificates: [
					{
						titleKey: "aboutMe.certificateSection.groups.2.certificates.0",
						image:
							window.location.origin +
							"/certificates/kobe_certificate_of_commendation_2014.webp",
					},
				],
			},
			{
				categoryKey: "aboutMe.certificateSection.groups.1.category",
				certificates: [
					{
						titleKey: "aboutMe.certificateSection.groups.1.certificates.0",
						image:
							window.location.origin +
							"/certificates/malaysia_famt_display_exhibition_competition_2006.webp",
					},
					{
						titleKey: "aboutMe.certificateSection.groups.1.certificates.1",
						image:
							window.location.origin +
							"/certificates/malaysia_famt_internation_technical_seminar_2006.webp",
					},
				],
			},
			{
				categoryKey: "aboutMe.certificateSection.groups.0.category",
				certificates: [
					{
						titleKey: "aboutMe.certificateSection.groups.0.certificates.0",
						image:
							window.location.origin +
							"/certificates/malaysia_ftg_certificate_of_attendance_2004.webp",
					},
				],
			},
		],
	},
};
