import {NavigationLink} from "../types";

export enum Lang {
	EN = "en",
	ZH = "zh_tw",
}

export enum Path {
	LandingPage = "",
	ContemporaryTradition = "contemporary-tradition",
	CraftsmanshipDetail = "craftsmanship-details",
	Gallery = "my-gallery",
	AboutMe = "about-me",
}

export const Links: NavigationLink[] = [
	{
		titleKey: "navigation.aboutMe",
		path: Path.AboutMe,
	},
	{
		titleKey: "navigation.contemporaryTradition",
		path: Path.ContemporaryTradition,
	},
	{
		titleKey: "navigation.craftsmanshipDetail",
		path: Path.CraftsmanshipDetail,
	},
	{
		titleKey: "navigation.gallery",
		path: Path.Gallery,
	},
];
