import {
	Box,
	createStyles,
	Drawer,
	Grid,
	Group,
	Stack,
	UnstyledButton,
} from "@mantine/core";
import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {NavigationLink} from "../../types";
import CustomTitle from "../CustomTitle";
import TranslationButton from "./translationButton";

type Props = {
	title: string;
	links: NavigationLink[];
	isTriggedMenu: boolean;
	setIsTriggedMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = createStyles((theme) => ({
	drawer: {
		color: "white",
		".mantine-Drawer-drawer": {
			background: "#0F4AE6",
		},
		".mantine-Drawer-header": {
			borderBottom: "1px solid white",
		},
	},
}));

export default function MobileMenu({
	title,
	links,
	isTriggedMenu,
	setIsTriggedMenu,
}: Props) {
	const {t} = useTranslation();
	const {classes} = useStyles();

	return (
		<Drawer
			opened={isTriggedMenu}
			onClose={() => setIsTriggedMenu(false)}
			title={
				<CustomTitle
					heading="h4"
					sx={{
						fontFamily: "Savoye LET Plain",
						fontStyle: "italic",
						fontSize: "2rem !important",
						fontWeight: "bold",
					}}
				>
					{title}
				</CustomTitle>
			}
			size="full"
			padding="md"
			className={classes.drawer}
		>
			<Group position="apart" align={"flex-start"}>
				<Stack sx={{color: "white"}}>
					{links.map((link, index) => (
						<Link
							key={index}
							to={link.path}
							onClick={() => setIsTriggedMenu((prev) => !prev)}
						>
							<UnstyledButton sx={{color: "white"}}>
								{t(link.titleKey)}
							</UnstyledButton>
						</Link>
					))}
				</Stack>
				<Stack align="flex-start">
					<TranslationButton
						color="white"
						onClick={() => setIsTriggedMenu(false)}
					/>
				</Stack>
			</Group>
		</Drawer>
	);
}
