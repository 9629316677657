import {Box, createStyles, MediaQuery, Image} from "@mantine/core";
import {useCallback, useEffect, useRef} from "react";
import {LandingContent} from "../../../constants";

export const LANDING_IMAGE_CHANGE_WIDTH = 1068;

export interface BackgroundProps {
	children: React.ReactNode;
}

const useStyles = createStyles((theme) => {
	return {
		container: {
			height: "100%",
			width: "100%",
			position: "relative",
			overflow: "hidden",
		},
		mask: {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			background: "rgba(0, 0, 0, 0.1)",
			zIndex: 8,
			[`@media only screen and (max-width: ${theme.breakpoints.lg}px)`]: {
				background:
					"linear-gradient(135deg, rgba(15, 74, 230, 0.3) 50%, rgba(0,212,255,0) 100%)",
			},
			[`@media only screen and (max-width: ${LANDING_IMAGE_CHANGE_WIDTH}px)`]: {
				background: "rgba(15, 74, 230, 0.3)",
			},
		},
		fadeBase: {
			position: "absolute",
			top: 0,
			left: 0,
			height: "100%",
			zIndex: 5,
			width: "100%",
			background: "rgba(103,118,136,1)",
		},
		landingImage: {
			"& figure,div": {
				height: "100%",
			},
			"& img": {
				position: "absolute",
				top: 0,
				right: 0,
			},
			[`@media only screen and (max-width: ${LANDING_IMAGE_CHANGE_WIDTH}px)`]: {
				objectFit: "cover",
				width: "100%",
				height: "100%",
			},
		},
		contentContainer: {
			zIndex: 10,
			position: "absolute",
			top: "5%",
			left: "30%",
			transform: "translate(-50%, 0)",
			display: "flex",
			alignItems: "center",
			height: "100%",
			[`@media only screen and (max-width: ${theme.breakpoints.lg}px)`]: {
				left: "max(35%, 380px)",
				margin: "auto",
			},
			[`@media only screen and (max-width: ${LANDING_IMAGE_CHANGE_WIDTH}px)`]: {
				left: "50%",
				margin: "auto",
				width: "max-content",
				top: "10%",
			},
		},
	};
});

export default function Background(props: BackgroundProps) {
	const {classes} = useStyles();
	const imageRef = useRef<HTMLImageElement | null>(null);
	const fadeRef = useRef<HTMLDivElement | null>(null);

	const updateStyle = useCallback(() => {
		if (!imageRef.current || !fadeRef.current) return;
		if (window.innerWidth <= LANDING_IMAGE_CHANGE_WIDTH) return;
		const compensateWidth = Math.max(
			Math.min(0, window.innerWidth - 1600),
			-220,
		);
		imageRef.current.style.right = `${compensateWidth}px`;
		const actualWidth =
			window.innerWidth - ((imageRef.current.width + compensateWidth) / 5) * 3;
		const fillPercentage = Math.max(
			30,
			Math.round(
				((window.innerWidth - imageRef.current.width - compensateWidth) /
					actualWidth) *
					100,
			),
		);
		fadeRef.current.style.width = `${actualWidth}px`;
		fadeRef.current.style.background = `linear-gradient(90deg, rgba(50,62,75,1) ${
			(fillPercentage * 1) / 100
		}%, rgba(103,118,136,1) ${fillPercentage}%, rgba(115,130,149,0.8) ${
			(100 - fillPercentage) / 2 + fillPercentage
		}%, rgba(109,122,140,0.01) 100%)`;
	}, []);

	useEffect(() => {
		if (!imageRef.current || !fadeRef.current) return;
		const timeout = setInterval(() => updateStyle(), 500);
		window.addEventListener("resize", updateStyle);
		return () => {
			if (timeout) clearInterval(timeout);
			window.removeEventListener("resize", updateStyle);
		};
	}, [updateStyle]);

	return (
		<Box className={classes.container}>
			<div className={classes.mask} />
			<MediaQuery
				smallerThan={LANDING_IMAGE_CHANGE_WIDTH}
				styles={{display: "none"}}
			>
				<div ref={fadeRef} className={classes.fadeBase} />
			</MediaQuery>
			<MediaQuery
				smallerThan={LANDING_IMAGE_CHANGE_WIDTH}
				styles={{display: "none"}}
			>
				<div style={{width: "auto", height: "100%"}}>
					<figure style={{height: "100%", margin: "0"}}>
						<div style={{height: "100%"}}>
							<img
								ref={imageRef}
								className={classes.landingImage}
								width="auto"
								height="100%"
								style={{
									objectFit: "contain",
									width: "auto",
									height: "100%",
									position: "absolute",
									top: "0px",
								}}
								src={LandingContent.desktopImage}
							/>
						</div>
					</figure>
				</div>
			</MediaQuery>
			<MediaQuery
				largerThan={LANDING_IMAGE_CHANGE_WIDTH}
				styles={{display: "none"}}
			>
				<div style={{width: "100%", height: "100%", objectFit: "cover"}}>
					<figure style={{height: "100%", margin: "0px"}}>
						<div style={{height: "100%"}}>
							<img
								className={classes.landingImage}
								style={{
									objectFit: "cover",
									width: "100%",
									height: "100%",
									position: "absolute",
									top: "0px",
									left: "0px",
								}}
								src={LandingContent.mobileImage}
							/>
						</div>
					</figure>
				</div>
			</MediaQuery>
			<Box className={classes.contentContainer}>{props.children}</Box>
		</Box>
	);
}
