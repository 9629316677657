import {
	Box,
	createStyles,
	UnstyledButton,
	Group,
	Space,
	MediaQuery,
	Stack,
} from "@mantine/core";
import {Link} from "react-router-dom";
import {LandingContent, FooterContent, Links, Path} from "../../constants";
import CustomTitle from "../../components/CustomTitle";
import Background, {LANDING_IMAGE_CHANGE_WIDTH} from "./background";
import NavigationBar from "../../components/NavigationBar";
import {useTranslation} from "react-i18next";
import Footer from "../../components/Footer";
import {useMediaQuery} from "@mantine/hooks";

const useStyles = createStyles(() => ({
	textSection: {
		textAlign: "center",
		color: "white",
	},
	mainTitle: {
		fontWeight: "bold",
		[`@media only screen and (max-width: ${LANDING_IMAGE_CHANGE_WIDTH}px)`]: {
			paddingTop: "30px",
			fontSize: "5rem !important",
		},
		fontSize: "8rem !important",
		fontFamily: "Savoye LET Plain",
		fontStyle: "italic",
	},
	subTitle: {
		fontWeight: 600,
		fontStyle: "italic",
		[`@media only screen and (max-width: ${LANDING_IMAGE_CHANGE_WIDTH}px)`]: {
			fontWeight: 500,
			fontSize: "1.5rem",
		},
	},
	quote: {
		fontWeight: 500,
		fontStyle: "italic",
		[`@media only screen and (max-width: ${LANDING_IMAGE_CHANGE_WIDTH}px)`]: {},
	},
	navButton: {
		color: "white",
		// color: "#EAB325",
		fontWeight: 700,
		"&: hover": {
			color: "#001755",
		},
	},
}));

export default function LandingPage() {
	const {classes} = useStyles();
	const {t} = useTranslation();
	const isDesktop = useMediaQuery("(min-width: 768px)");

	return (
		<>
			<MediaQuery largerThan={"sm"} styles={{display: "none"}}>
				<Box>
					<NavigationBar
						title="Luxe's Bespoke"
						links={Links}
						redirectIconPath={Path.LandingPage}
					/>
				</Box>
			</MediaQuery>
			<Background>
				<Box className={classes.textSection}>
					<CustomTitle heading="h3" className={classes.mainTitle}>
						{t(LandingContent.mainTitleKey)}
					</CustomTitle>
					<Space h="md" />
					<CustomTitle heading="h4" className={classes.subTitle}>
						{t(LandingContent.subTitleKey)}
					</CustomTitle>
					<Space h={isDesktop ? 50 : "md"} />

					<CustomTitle heading="h6" className={classes.quote}>
						{t(LandingContent.quoteKey)}
					</CustomTitle>

					<Space h={isDesktop ? 50 : "md"} />
					<Group
						noWrap
						align="flex-start"
						position="center"
						sx={{
							flexDirection: "column",
							alignItems: "flex-start",
							...(!isDesktop && {
								alignItems: "center",
							}),
						}}
					>
						{Links.map((link, index) => (
							<Link to={link.path} key={index}>
								<UnstyledButton className={classes.navButton}>
									<CustomTitle
										heading="h6"
										sx={{
											textAlign: "center",
										}}
									>
										{t(link.titleKey)}
									</CustomTitle>
								</UnstyledButton>
							</Link>
						))}
					</Group>

					{/* <Space h={isDesktop ? 50 : "md"} /> */}

					<Footer contacts={FooterContent.contacts} />
				</Box>
			</Background>
		</>
	);
}
