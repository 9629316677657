import {
	Grid,
	Space,
	Stack,
	Image,
	Box,
	Text,
	Divider,
	createStyles,
} from "@mantine/core";
import {useMediaQuery, useScrollIntoView} from "@mantine/hooks";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import HeroHeader from "../../components";
import CustomTitle from "../../components/CustomTitle";
import {CraftsmanshipDetailContent} from "../../constants";
import ContentLayout from "../../layouts/contentLayout";

function ImageGrid({
	image,
	title,
	onClick,
}: {
	image: string;
	title: string;
	onClick: () => void;
}) {
	return (
		<Stack onClick={onClick} sx={{cursor: "pointer"}} align="center">
			<Image
				width={"100%"}
				height={"100%"}
				src={image}
				sx={() => ({
					"& img": {
						maxWidth: "min(100%, 300px)",
						aspectRatio: "1 / 1",
					},
				})}
				radius="md"
				withPlaceholder
			/>
			<CustomTitle heading="h6">{title}</CustomTitle>
		</Stack>
	);
}

export default function CraftsmanshipDetailsPage() {
	const {t} = useTranslation();
	const {scrollIntoView, targetRef} = useScrollIntoView<HTMLDivElement>({
		offset: 60,
	});
	const [selectedGrid, setSelectedGrid] = useState<number>(-1);
	const isDesktop = useMediaQuery("(min-width: 768px)");
	const isTablet = useMediaQuery("(min-width: 500px)");

	useEffect(() => {
		scrollIntoView();
	}, [selectedGrid]);

	return (
		<>
			<HeroHeader
				title={t(CraftsmanshipDetailContent.titleKey)}
				description={t(CraftsmanshipDetailContent.titleDescriptionKey)}
			/>
			<ContentLayout>
				<Grid
					gutter={isDesktop ? 60 : 30}
					sx={() => ({
						margin: "unset",
					})}
				>
					{CraftsmanshipDetailContent.sections.map((section, i) => {
						return (
							<Grid.Col key={i} span={isDesktop ? 4 : 6}>
								<ImageGrid
									image={section.image}
									title={t(section.titleKey)}
									onClick={() => setSelectedGrid(i)}
								/>
							</Grid.Col>
						);
					})}
				</Grid>
				<Space h={isDesktop ? 80 : 40} />
				<Divider my="sm" />
				<Space h={40} />

				<Stack spacing={40} align="center">
					{CraftsmanshipDetailContent.sections.map((section, i) => {
						return (
							<Box
								ref={i === selectedGrid ? targetRef : null}
								key={i}
								sx={(theme) => ({
									borderBottom: "1px solid #ced4da",
									paddingBottom: 40,
									[theme.fn.smallerThan("sm")]: {
										paddingBottom: 20,
									},
								})}
							>
								<Grid justify="center">
									<Grid.Col md={4} xs={12}>
										<Image
											width={isTablet ? 300 : "100%"}
											height={isTablet ? 300 : "100%"}
											src={section.image}
											radius="md"
											withPlaceholder
											sx={() => ({
												"& img": {
													aspectRatio: "1 / 1",
												},
											})}
										/>
									</Grid.Col>
									<Grid.Col md={8} xs={12}>
										<Stack sx={{paddingTop: 20, paddingBottom: 20}}>
											<CustomTitle heading="h6">
												{t(section.titleKey)}
											</CustomTitle>
											<Stack>
												{section.paragraphKeys.map((contentKey, index) => (
													<Text key={index} size="xl">
														{t(contentKey)}
													</Text>
												))}
											</Stack>
										</Stack>
									</Grid.Col>
								</Grid>
							</Box>
						);
					})}
				</Stack>
			</ContentLayout>
		</>
	);
}
