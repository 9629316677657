import {Anchor, Box, Group, Image, Stack, Text, Sx} from "@mantine/core";
import {ActionIcon} from "@mantine/core";

type Props = {
	message?: string;
	contacts?: CommunicationMethod[];
	sx?: Sx;
};

type CommunicationMethod = {
	src: string;
	width: string;
	height: string;
	href: string;
};

export default function Footer(props: Props) {
	const {message = "", contacts = [], sx} = props;
	return (
		<Stack align="center" spacing={12} sx={{...sx}}>
			<Box
				sx={{
					paddingTop: "15px",
					paddingBottom: "15px",
				}}
			>
				<Text align="center" hidden={message.length === 0}>
					{message}
				</Text>
			</Box>
			<Group spacing={20}>
				{contacts.map((method, index) => (
					<ActionIcon
						key={index}
						variant="filled"
						sx={{
							backgroundColor: "#262D36",
							borderRadius: "50%",
							width: "36px",
							height: "36px",
						}}
					>
						<Anchor href={method.href}>
							<Image
								src={method.src}
								width={method.width}
								height={method.height}
							/>
						</Anchor>
					</ActionIcon>
				))}
			</Group>
		</Stack>
	);
}
