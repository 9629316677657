import {
	Box,
	Group,
	Image,
	Modal,
	Space,
	Stack,
	Text,
	UnstyledButton,
} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import HeroHeader from "../../components";
import CustomTitle from "../../components/CustomTitle";
import {AboutMeContent} from "../../constants";
import ContentLayout from "../../layouts/contentLayout";

type ModalProps = {
	opened: boolean;
	src: string;
};

export default function BioAndCertificatePage() {
	const {t} = useTranslation();
	const isDesktop = useMediaQuery("(min-width: 768px)");
	const [modalProps, setModalProps] = useState<ModalProps>({
		opened: false,
		src: "",
	});

	return (
		<>
			<HeroHeader
				title={t(AboutMeContent.titleKey)}
				description={t(AboutMeContent.titleDescriptionKey)}
			/>
			<ContentLayout>
				<Stack
					spacing={120}
					align="center"
					sx={(theme) => ({
						[theme.fn.smallerThan("sm")]: {
							gap: 10,
						},
					})}
				>
					<Group
						spacing={80}
						position="center"
						align="flex-start"
						sx={(theme) => ({
							width: "100%",
							[theme.fn.smallerThan("md")]: {
								alignItems: "center",
								flexWrap: "wrap-reverse",
								gap: 30,
							},
						})}
					>
						<Box
							sx={(theme) => ({
								width: "min(400px, 100%)",
								[theme.fn.smallerThan("md")]: {
									width: "min(500px, 100%) !important",
								},
								[theme.fn.smallerThan("sm")]: {
									width: "100% !important",
								},
							})}
						>
							<CustomTitle heading="h4">
								{t(AboutMeContent.introductionSection.titleKey)}
							</CustomTitle>
							<Space h="xl" />
							<Stack spacing="sm">
								{AboutMeContent.introductionSection.paragraphKeys.map(
									(contentKey, index) => (
										<Text key={index} size="xl">
											{t(contentKey)}
										</Text>
									),
								)}
							</Stack>
						</Box>
						<Image
							width={400}
							height={650}
							src={AboutMeContent.introductionSection.image}
							radius="md"
							withPlaceholder
							sx={(theme) => ({
								[theme.fn.smallerThan("md")]: {
									width: "500px !important",
									height: "500px !important",
								},
								[theme.fn.smallerThan("sm")]: {
									width: "100% !important",
									height: "100% !important",
								},
								"& img": {
									objectPosition: "-116px",
									[theme.fn.smallerThan("md")]: {
										width: "500px !important",
										height: "500px !important",
										objectPosition: "unset",
									},
									[theme.fn.smallerThan("sm")]: {
										width: "100% !important",
										height: "100% !important",
									},
								},
							})}
						/>
					</Group>
				</Stack>
				<Space
					h={120}
					sx={(theme) => ({
						[theme.fn.smallerThan("sm")]: {
							height: 30,
							minHeight: 30,
						},
					})}
				/>
				<Stack spacing={0} align="center">
					<CustomTitle heading="h4">Certificates</CustomTitle>
					<Space
						h={20}
						sx={(theme) => ({
							[theme.fn.smallerThan("sm")]: {
								height: 10,
								minHeight: 10,
							},
						})}
					/>
					{AboutMeContent.certificateSection.groups.map((section, i) => {
						return (
							<Box
								key={i}
								sx={(theme) => ({
									width: "880px",
									borderTop: "1px solid #ced4da",
									paddingTop: 40,
									paddingBottom: 40,
									[theme.fn.smallerThan("md")]: {
										width: "500px",
									},
									[theme.fn.smallerThan("sm")]: {
										width: "100%",
										paddingTop: 20,
										paddingBottom: 20,
									},
								})}
							>
								<Group
									spacing={100}
									sx={(theme) => ({
										flexWrap: "nowrap",
										alignItems: "start",
										[theme.fn.smallerThan("md")]: {
											gap: 60,
										},
										[theme.fn.smallerThan("sm")]: {
											gap: 30,
										},
									})}
								>
									<CustomTitle heading="h5">
										{t(section.categoryKey)}
									</CustomTitle>
									<Stack
										sx={{
											width: "100%",
											"button:not(:first-child)": {
												"&::before": {
													position: "relative",
													left: "50%",
													content: "'&'",
												},
											},
										}}
										spacing={0}
									>
										{section.certificates.map((certificate, i) => (
											<UnstyledButton
												key={i}
												onClick={() =>
													setModalProps({
														opened: true,
														src: certificate.image,
													})
												}
											>
												<Text
													key={i}
													size="xl"
													sx={(theme) => ({
														textAlign: "center",
														fontStyle: "italic",
														[theme.fn.smallerThan("sm")]: {
															fontSize: "1rem",
														},
														"&:hover": {
															color: "#0F4AE6",
														},
													})}
												>
													{t(certificate.titleKey)}
												</Text>
											</UnstyledButton>
										))}
									</Stack>
								</Group>
							</Box>
						);
					})}
				</Stack>
				<Modal
					opened={modalProps.opened}
					onClose={() => setModalProps({opened: false, src: ""})}
					size="xl"
					overlayOpacity={0.55}
					overlayBlur={3}
					fullScreen={!isDesktop}
					centered
					sx={() => ({
						"& > div > div": {
							// background: `url(${AboutMeContent.titleImage})`,
							backgroundRepeat: "repeat-y",
							backgroundSize: "contain",
						},
					})}
				>
					<Image src={modalProps.src} />
				</Modal>
			</ContentLayout>
		</>
	);
}
