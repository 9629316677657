import {PropsWithChildren, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {
	Routes as ReactRoutes,
	Route,
	Navigate,
	useRoutes,
	useLocation,
} from "react-router-dom";
import {Lang, Path} from "../constants";
import Layout from "../layouts";
import BioAndCertificatePage from "../pages/bioAndCertificatePage";
import ContemporaryTraditionPage from "../pages/contemporaryTraditionPage";
import CraftsmanshipDetailsPage from "../pages/craftsmanshipDetailsPage";
import FallbackPage from "../pages/fallbackPage";
import GalleryPage from "../pages/galleryPage";
import LandingPage from "../pages/landingPage";

export default function Routes() {
	return (
		<ReactRoutes>
			{/* en */}
			<Route path={"/" + Lang.EN} element={<LandingPage />} />
			<Route path={"/" + Lang.EN} element={<Layout />}>
				<Route
					path={Path.ContemporaryTradition}
					element={<ContemporaryTraditionPage />}
				/>
				<Route
					path={Path.CraftsmanshipDetail}
					element={<CraftsmanshipDetailsPage />}
				/>
				<Route path={Path.AboutMe} element={<BioAndCertificatePage />} />
				<Route path={Path.Gallery} element={<GalleryPage />} />
				<Route path="*" element={<Navigate to={"/" + Lang.EN} />} />
			</Route>
			<Route path="*" element={<Navigate to="/en" />} />

			{/* zh */}
			<Route path={"/" + Lang.ZH} element={<LandingPage />} />
			<Route path={"/" + Lang.ZH} element={<Layout />}>
				<Route
					path={Path.ContemporaryTradition}
					element={<ContemporaryTraditionPage />}
				/>
				<Route
					path={Path.CraftsmanshipDetail}
					element={<CraftsmanshipDetailsPage />}
				/>
				<Route path={Path.AboutMe} element={<BioAndCertificatePage />} />
				<Route path={Path.Gallery} element={<GalleryPage />} />
				<Route path="*" element={<Navigate to={"/" + Lang.ZH} />} />
			</Route>
			<Route path="*" element={<Navigate to="/en" />} />
		</ReactRoutes>
	);
}
