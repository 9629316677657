import {Container, createStyles} from "@mantine/core";

type Props = {
	children: React.ReactNode;
};

const useStyles = createStyles((theme) => ({
	container: {
		marginTop: 40,
		marginBottom: 120,
		[`@media only screen and (max-width: ${theme.breakpoints.xs}px)`]: {
			marginTop: 20,
			marginBottom: 60,
		},
	},
}));

export default function ContentLayout({children}: Props) {
	const {classes} = useStyles();

	return (
		<Container size="lg" px="xs" className={classes.container}>
			{children}
		</Container>
	);
}
