import {Box, Group, Image, Space, Stack, Text} from "@mantine/core";
import {useTranslation} from "react-i18next";
import HeroHeader from "../../components";
import CustomTitle from "../../components/CustomTitle";
import {ContemporaryTraditionContent} from "../../constants";
import ContentLayout from "../../layouts/contentLayout";

export default function ContemporaryTraditionPage() {
	const {t} = useTranslation();
	return (
		<>
			<HeroHeader
				title={t(ContemporaryTraditionContent.titleKey)}
				description={t(ContemporaryTraditionContent.titleDescriptionKey)}
			/>
			<ContentLayout>
				<Stack
					spacing={120}
					align="center"
					sx={(theme) => ({
						[theme.fn.smallerThan("sm")]: {
							gap: 10,
						},
					})}
				>
					{ContemporaryTraditionContent.sections.map((section, i) => (
						<Group
							key={i}
							spacing={87}
							position="center"
							align="flex-start"
							sx={(theme) => ({
								flexDirection: i % 2 === 0 ? "row" : "row-reverse",
								width: "100%",
								[theme.fn.smallerThan("sm")]: {
									alignItems: "center",
									flexWrap: "wrap-reverse",
									gap: 30,
								},
							})}
						>
							<Image
								width={400}
								height={400}
								src={section.image}
								radius="md"
								withPlaceholder
							/>
							<Box sx={{width: "min(400px, 100%)"}}>
								<Space h="lg" />
								<CustomTitle heading="h4">{t(section.titleKey)}</CustomTitle>
								<Space h="xl" />
								<Stack spacing="sm">
									{section.paragraphKeys.map((contentKey, index) => (
										<Text key={index} size="xl">
											{t(contentKey)}
										</Text>
									))}
								</Stack>
							</Box>
						</Group>
					))}
				</Stack>
			</ContentLayout>
		</>
	);
}
