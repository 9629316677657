import {Box, Container, createStyles, Divider} from "@mantine/core";
import {Outlet} from "react-router-dom";
import Footer from "../components/Footer";
import NavigationBar from "../components/NavigationBar";
import {FooterContent, Links, Path} from "../constants";

const useStyles = createStyles((theme) => ({
	container: {
		minHeight: "100vh",
		backgroundColor: "#fafafa;",
	},
}));

export default function Layout() {
	const {classes} = useStyles();

	return (
		<Container p={0} fluid className={classes.container}>
			<NavigationBar
				title="Luxe's Bespoke"
				links={Links}
				redirectIconPath={Path.LandingPage}
			/>
			<Box
				sx={(theme) => ({
					width: "100%",
					[theme.fn.smallerThan("sm")]: {
						paddingLeft: 10,
						paddingRight: 10,
					},
				})}
			>
				<Outlet />
				<Divider size="xs" />
				<Footer
					contacts={FooterContent.contacts}
					message={FooterContent.message}
					sx={{paddingTop: 30, paddingBottom: 30}}
				/>
			</Box>
		</Container>
	);
}
