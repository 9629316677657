import { createStyles, MantineSize, TitleOrder } from "@mantine/core";
import { CSSProperties } from "react";


type Heading = `h${TitleOrder}`;

type TitleSizeMap = {
  [key in Heading]: {
    [key in MantineSize]: CSSProperties["fontSize"];
  };
};

const TITLE_SIZE: TitleSizeMap = {
  h1: {
    xs: "4rem",
    sm: "5rem",
    md: "6rem",
    lg: "7rem",
    xl: "8rem",
  },
  h2: {
    xs: "3rem",
    sm: "4rem",
    md: "5rem",
    lg: "6rem",
    xl: "7rem",
  },
  h3: {
    xs: "2rem",
    sm: "3rem",
    md: "4rem",
    lg: "5rem",
    xl: "6rem",
  },
  h4: {
    xs: "1.5rem",
    sm: "1.5rem",
    md: "2rem",
    lg: "2rem",
    xl: "2rem",
  },
  h5: {
    xs: "1rem",
    sm: "1.5rem",
    md: "1.5rem",
    lg: "1.5rem",
    xl: "1.5rem",
  },
  h6: {
    xs: "1rem",
    sm: "1.5rem",
    md: "1.5rem",
    lg: "1.5rem",
    xl: "1.5rem",
  },
};

const useHeaderStyle = (heading: Heading) => {
  return createStyles((theme) => ({
    heading: {
      [theme.fn.smallerThan("xs")]: {
        fontSize: TITLE_SIZE[heading]["xs"],
      },
      [theme.fn.largerThan("xs")]: {
        fontSize: TITLE_SIZE[heading]["xs"],
      },
      [theme.fn.largerThan("sm")]: {
        fontSize: TITLE_SIZE[heading]["sm"],
      },
      [theme.fn.largerThan("md")]: {
        fontSize: TITLE_SIZE[heading]["md"],
      },
      [theme.fn.largerThan("lg")]: {
        fontSize: TITLE_SIZE[heading]["lg"],
      },
      [theme.fn.largerThan("xl")]: {
        fontSize: TITLE_SIZE[heading]["xl"],
      },
    },
  }))();
};

export default useHeaderStyle;
