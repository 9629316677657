import {
	Title,
	Text,
	Container,
	Button,
	Overlay,
	createStyles,
	Box,
} from "@mantine/core";

type Props = {
	title: string;
	description: string;
};

const useStyles = createStyles((theme) => ({
	wrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: "200px",

		"@media (max-width: 520px)": {
			height: "100px",
		},
	},

	inner: {
		position: "relative",
		zIndex: 1,
	},

	title: {
		fontWeight: 800,
		fontSize: 40,
		letterSpacing: -1,
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
		color: theme.white,
		marginBottom: theme.spacing.xs,
		textAlign: "center",
		fontStyle: "italic",
		"@media (max-width: 520px)": {
			fontSize: 28,
			textAlign: "left",
		},
	},

	description: {
		color: theme.colors.gray[0],
		textAlign: "center",

		"@media (max-width: 520px)": {
			fontSize: theme.fontSizes.md,
			textAlign: "left",
		},
	},
}));

export default function HeroHeader({title, description}: Props) {
	const {classes, cx} = useStyles();

	return (
		<Box className={classes.wrapper} sx={{backgroundColor: "#0F4AE6"}}>
			<Box className={classes.inner}>
				<Title className={classes.title}>{title}</Title>

				<Container size={640}>
					<Text size="lg" className={classes.description}>
						{description}
					</Text>
				</Container>
			</Box>
		</Box>
	);
}
