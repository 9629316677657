import {ContemporaryTraditionContent as IContemporaryTraditionContent} from "../types";

export const ContemporaryTraditionContent: IContemporaryTraditionContent = {
	titleKey: "contemporaryTradition.title",
	titleDescriptionKey: "",
	sections: [
		{
			titleKey: "contemporaryTradition.sections.0.title",
			paragraphKeys: ["contemporaryTradition.sections.0.paragraphs.0"],
			image:
				window.location.origin +
				"/contemporaryTradition/menBespokeTailoring.webp",
		},

		{
			titleKey: "contemporaryTradition.sections.1.title",
			paragraphKeys: ["contemporaryTradition.sections.1.paragraphs.0"],
			image:
				window.location.origin +
				"/contemporaryTradition/choosingTheModelAndFabric.webp",
		},
		{
			titleKey: "contemporaryTradition.sections.2.title",
			paragraphKeys: ["contemporaryTradition.sections.2.paragraphs.0"],
			image: window.location.origin + "/contemporaryTradition/theFit.webp",
		},
		{
			titleKey: "contemporaryTradition.sections.3.title",
			paragraphKeys: ["contemporaryTradition.sections.3.paragraphs.0"],
			image:
				window.location.origin + "/contemporaryTradition/makingItPersonal.webp",
		},
	],
};
