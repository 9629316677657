import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-http-backend";
import {Lang} from "./constants";

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		backend: {
			loadPath: `${window.location.origin}/locales/{{lng}}/{{ns}}.json`,
		},
		fallbackLng: "en",
		lng: window.location.pathname.includes(Lang.ZH) ? Lang.ZH : Lang.EN,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
