import {Burger} from "@mantine/core";

type Props = {
	isShown: boolean;
	isTriggedMenu: boolean;
	setIsTriggedMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MobileBurger({
	isShown,
	isTriggedMenu,
	setIsTriggedMenu,
}: Props) {
	if (isShown) {
		return (
			<Burger
				ml={10}
				// sx={{backgroundColor: "#0F4AE6"}}
				color={"#0F4AE6"}
				opened={isTriggedMenu}
				onClick={() => setIsTriggedMenu((prev) => !prev)}
			/>
		);
	}
	return <></>;
}
