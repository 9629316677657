import {createStyles, Divider, Group, UnstyledButton} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useRoutes} from "react-router-dom";
import {Lang} from "../../constants";

type Props = {
	color?: string;
	onClick?: () => void;
};

const useStyle = createStyles(() => ({
	translationButton: {
		"&: hover": {
			color: "#0F4AE6",
		},
	},
}));

export default function TranslationButton({color, onClick}: Props) {
	const nav = useNavigate();
	const location = useLocation();
	const {i18n} = useTranslation();
	const {classes} = useStyle();

	const changeLanguage = (lang: Lang) => {
		nav({pathname: location.pathname.replace(i18n.language, lang)});
		i18n.changeLanguage(lang);
		onClick && onClick();
	};

	return (
		<Group position="right" pr={20}>
			<UnstyledButton
				className={classes.translationButton}
				onClick={() => changeLanguage(Lang.EN)}
				sx={{color}}
			>
				EN
			</UnstyledButton>
			<Divider orientation="vertical" />
			<UnstyledButton
				className={classes.translationButton}
				onClick={() => changeLanguage(Lang.ZH)}
				sx={{color}}
			>
				繁中
			</UnstyledButton>
		</Group>
	);
}
