export const FooterContent = {
	message: "Copyright © 2024 Luxe's Bespoke. All Rights Reserved",
	contacts: [
		{
			src: `${window.location.origin}/icons/phone.svg`,
			width: "21px",
			height: "21px",
			href: "tel:+603 8680 6962",
		},
		{
			src: `${window.location.origin}/icons/email.svg`,
			width: "26px",
			height: "26px",
			href: "mailto:bernard@luxesbespoke.com?subject=Enquiry",
		},
		{
			src: `${window.location.origin}/icons/whatsapp.svg`,
			width: "21px",
			height: "20px",
			href: "https://wa.me/60386806962",
		},
	],
};
