import {Divider, Image} from "@mantine/core";
import {Carousel} from "@mantine/carousel";
import HeroHeader from "../../components";
import {GalleryContent} from "../../constants";
import {useTranslation} from "react-i18next";
import ContentLayout from "../../layouts/contentLayout";
import {useMediaQuery} from "@mantine/hooks";

export default function GalleryPage() {
	const {t} = useTranslation();
	const isDesktop = useMediaQuery("(min-width: 768px)");

	return (
		<>
			<HeroHeader
				title={t(GalleryContent.titleKey)}
				description={t(GalleryContent.titleDescriptionKey)}
			/>
			<ContentLayout>
				<Carousel
					withIndicators
					mx="auto"
					height={isDesktop ? 800 : "100%"}
					sx={(theme) => ({
						width: 800,
						[theme.fn.smallerThan("sm")]: {
							width: "auto",
						},
					})}
				>
					{GalleryContent.images.map((url) => (
						<Carousel.Slide key={url}>
							<Image src={url} height={isDesktop ? 800 : 300} fit="contain" />
						</Carousel.Slide>
					))}
				</Carousel>
			</ContentLayout>
		</>
	);
}
